.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg) ;
  }
}


/* Checkbox Styling*/

.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Create a custom checkbox */
.custom-checkbox {
  display: flex;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 1px; /* Rounded corners */
  border: 1px solid #A32318;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #A32318;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
