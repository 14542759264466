.splide__pagination__page {
    background: #ccc;
    border: 0;
    border-radius: 25px !important;
    display: inline-block;
    height: 4px !important;
    margin: 7px !important;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 40px !important;
  }