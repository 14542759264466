
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    /* text-align: center; */
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-wrapper{
    /* margin-left: 6vw !important; */
  }
  

  .mySwiper .swiper-slide {
    /* width: calc((100% / 3) - 30px); Adjust the width of each slide to accommodate spaceBetween  */
  }